import * as Sentry from '@sentry/react'
import { cloneDeep, Dictionary, findLastIndex, isNil } from 'lodash'
import MathLive from 'mathlive'
import {
	DELIMITER_PAIRS,
	DELIMITER_PAIRS_INVERTED,
	DELIMITER_SYMBOLS,
	EXPRESSION_COMMANDS,
	ITERATOR_COMMANDS,
	NUMERIC_SOLUTION_COMMANDS_NO_GREEK,
	NUMERIC_SUPPORTED_NON_VARIABLE_SYMBOLS
} from '../constants/commands'
import { INLINE_SHORTCUTS } from '../constants/mathlive'
import { VALIDITY_LEVEL } from '../constants/validity'
import { latexBracketVariableRegEx, variableNameRegEx, variableNameWrappedSingleSubRegEx } from '../constants/variable'
import { VARIABLE_TYPE, VariableState } from '../types/Variable'
import { extractVariable, unwrapWrappedSingleSubscript } from './variable'

export interface SymbolResult {
	variables: string[]
	symbols: string[]
	iteratorSymbols: string[]
}

export const findVariableEl = (el: HTMLElement | null): HTMLElement | null => {
	if (!el || !el.parentElement || el.classList.contains('ML__fieldcontainer__field')) {
		return null
	}
	if (el.classList.contains('ML__variable')) {
		return el
	}
	return findVariableEl(el.parentElement)
}

export const findVariableAtom = (mathlist: any, el: HTMLElement) => {
	let result: any
	const firstChild = el.childNodes[0] as HTMLElement
	const id = firstChild.dataset.atomId
	if (!id) {
		return result
	}
	mathlist.forEach((atom: any, parents: any[]) => {
		if (atom.id === id) {
			result = parents[0]
		}
	})
	return result
}

export const getVariableAtomName = (atom: any) => {
	const latex = atom.toLatex()
	// unwrap "\variable{" and "}"
	return latex.substring(10, latex.length - 1)
}

/**
 * Return the name of a symbol `atom`, accounting for a possible subscript in the parent, which modifies the symbol name
 */
export const getSymbolAtomName = (atom: any, parent: any) => {
	let symbolLatex = atom.latex

	// return early if no parent is provided
	if (!parent) return symbolLatex

	const atomAndSiblings = getParentPropertyContainingAtom(atom, parent)
	const atomIndex: number = atomAndSiblings.findIndex((a: any) => a === atom)
	if (atomIndex !== -1 && atomIndex !== atomAndSiblings.length - 1) {
		const nextAtom = atomAndSiblings[atomIndex + 1]
		if (nextAtom.type === 'msubsup' && !!nextAtom.subscript) {
			const possibleLatex = `${atom.latex}_{${nextAtom.subscript.map((s: any) => s.latex).join('')}}`
			if (variableNameRegEx.exec(possibleLatex)) {
				symbolLatex = possibleLatex
			}
		}
	}
	return symbolLatex
}

export const getSymbols = (rootAtom: any) => {
	const result: SymbolResult = { variables: [], symbols: [], iteratorSymbols: [] }
	const atomHistory: Array<{ atom: any; parents: any[] }> = []
	rootAtom.forEach((atom: any, parents: any[]) => {
		// find atom type, use originalType for error atoms
		const type = atom.originalType || atom.type
		// do nothing with atoms inside of a variable, nested at 1 or 2 levels
		const parent = parents && parents[0]
		const grandparent = parents && parents[1]
		if (
			(parent && parent.type === 'variable') ||
			(parent && parent.type === 'msubsup' && grandparent && grandparent.type === 'variable')
		) {
			atomHistory.unshift({ atom, parents })
			return
		}
		// variables
		if (type === 'variable') {
			const name = getVariableAtomName(atom)
			// do not add duplicates
			if (!result.variables.includes(name)) {
				result.variables.push(name)
			}
		}
		// symbols (no commands, no built in symbols, no numbers)
		else if (
			type === 'mord' &&
			atom.latex &&
			!NUMERIC_SOLUTION_COMMANDS_NO_GREEK.includes(atom.latex) &&
			!NUMERIC_SUPPORTED_NON_VARIABLE_SYMBOLS.includes(atom.body) &&
			!DELIMITER_SYMBOLS.includes(atom.latex) &&
			!/[0-9]+/.test(atom.latex)
		) {
			// symbol used in the subscript of an iteratorOp, it's an iteratorSymbol
			if (
				parent &&
				parent.type === 'mop' &&
				ITERATOR_COMMANDS.some(c => c.latex === parent.latex) &&
				parent.subscript &&
				parent.subscript.indexOf(atom) > -1
			) {
				const symbolLatex = getSymbolAtomName(atom, parent)
				if (!result.iteratorSymbols.includes(symbolLatex)) {
					result.iteratorSymbols.push(symbolLatex)
				}
			} else {
				// iteratorSymbol used as an argument (possibly nested) of an iteratorOp
				let isIteratorArg = false
				const symbolLatex = getSymbolAtomName(atom, parent)
				// only try checking if we know the atom is marked as an iteratorSymbol
				if (result.iteratorSymbols.includes(symbolLatex)) {
					isIteratorArg = getIsIteratorArg(atom, parents, atomHistory)
				}
				// all other symbols
				if (!isIteratorArg) {
					if (!result.symbols.includes(symbolLatex)) {
						result.symbols.push(symbolLatex)
					}
				}
			}
		}
		atomHistory.unshift({ atom, parents })
	})
	return result
}

/**
 * For a given atom, check if it or any of its parents is an argument to an iterator command.
 *
 * Loops through previous sibling atoms, then parents and their previous siblings.
 * Stops if it runs out of siblings, parents, or if an iterator command is found.
 */
const getIsIteratorArg = (atom: any, parents: any[], atomHistory: Array<{ atom: any; parents: any[] }>) => {
	let isIteratorArg = false
	const atomsToCheck = [atom, ...parents]
	while (atomsToCheck.length > 0 && !isIteratorArg) {
		const atomToCheck = atomsToCheck.shift()
		const parent = atomsToCheck[0]
		const prevSiblings = atomHistory.filter(p => p.parents[0] === parent && p.atom !== atomToCheck)
		let foundPlusOrMinus = false
		while (prevSiblings.length > 0 && !isIteratorArg) {
			const prevSibling = prevSiblings.shift()
			if (prevSibling && prevSibling.atom && (prevSibling.atom.latex === '+' || prevSibling.atom.latex === '-')) {
				foundPlusOrMinus = true
			}
			isIteratorArg =
				// check if previous sibling is an iterator command
				!!prevSibling &&
				!!prevSibling.atom &&
				prevSibling.atom.type === 'mop' &&
				ITERATOR_COMMANDS.some(c => c.latex === prevSibling.atom.latex) &&
				// don't mark as an arg if we found a plus/minus before the symbol and we aren't nested
				(!foundPlusOrMinus || atomToCheck !== atom)
		}
	}
	return isIteratorArg
}

export const findAndReplaceBracketVariables = (
	latex: string,
	isSnapshot: boolean,
	existingVariables?: VariableState[]
) => {
	let newLatex = latex

	// find bracket variables, replace them with \variable commands
	let match = latexBracketVariableRegEx.exec(latex)
	while (match) {
		const variableName = match[1]
		const { isNew } = extractVariable(variableName, existingVariables)
		// update latex
		if (!isSnapshot || (isSnapshot && !isNew)) {
			newLatex = newLatex.replace(match[0], `\\variable{${variableName}}`)
		}
		if (isSnapshot && isNew) {
			newLatex = newLatex.replace(match[0], variableName)
		}
		match = latexBracketVariableRegEx.exec(latex)
	}

	if (newLatex === latex) {
		return undefined
	}

	return newLatex
}

export const getEmptyParamVariableNames = (mathlist: any, variables?: VariableState[]): string[] => {
	const variableNames = getSymbols(mathlist).variables
	return (
		variables?.reduce((it, v) => {
			if (variableNames.includes(v.name) && !v.parameters && v.variableType !== VARIABLE_TYPE.RICH_TEXT) {
				return [...it, v.name]
			}
			return it
		}, [] as string[]) ?? []
	)
}

/** Iterates all atoms in the mathlist and returns a list of undefined variable names */
export const getUndefinedVariableNames = (mathlist: any, variables?: VariableState[]): string[] => {
	const atomVariableNames = getSymbols(mathlist).variables
	const definedVariableNames = variables?.map(v => v.name) ?? []
	// account for "legacy" variable names
	// e.g. when the variable may be saved with an unwrapped single char subscript
	// mathlive automatically wraps all subscripts, so also comparing to an unwrapped version
	// allows these to not be seen as an error
	const undefinedVariableNames = atomVariableNames.filter(
		n => !definedVariableNames.includes(n) && !definedVariableNames.includes(unwrapWrappedSingleSubscript(n))
	)
	return undefinedVariableNames
}

/** Iterates all atoms in the mathlist and returns a list of rich text variable names that are used */
export const getRichTextVariableNames = (mathlist: any, variables?: VariableState[]): string[] => {
	const variableNames = getSymbols(mathlist).variables
	return (
		variables?.reduce((it, v) => {
			if (variableNames.includes(v.name) && v.variableType === VARIABLE_TYPE.RICH_TEXT) {
				return [...it, v.name]
			}
			return it
		}, [] as string[]) ?? []
	)
}

/**
 * For a given `atom` and its `parents` array,
 * check it is a valid symbol by seeing if there is a `\int` + `\differentialD` pair that targets `atom`’s symbol name.
 *
 * For Future:
 * Check to see if `\int`s are ignored from math errors (doesn’t catch boundary errors)
 */
export const isValidIntegralSymbol = (atom: any, parents: any, symbolResult: SymbolResult) => {
	// validate arguments exist
	if (atom === undefined || parents === undefined || !atom.latex) return false

	const atomSymbolName = getSymbolAtomName(atom, parents[0])
	// return false if `atom` is not a symbol with a name
	if (!atomSymbolName || !symbolResult.symbols.includes(atomSymbolName)) return false

	// find an `\int` + `\differentialD` pair that targets `atom`’s symbol name
	// if a match is not found, loops upwards through `atom`’s parents, and checks each level
	for (let parentLevel = 0; parentLevel < parents.length; parentLevel++) {
		// `atom` of its parent in the current level
		const currentLevelAtom = parentLevel === 0 ? atom : parents[parentLevel - 1]
		// get the siblings of `currentLevelAtom`
		const atomsInLevel = getParentPropertyContainingAtom(currentLevelAtom, parents[parentLevel])
		const currentLevelAtomIndex = atomsInLevel.indexOf(currentLevelAtom)
		const symbolNamesInLevel: string[] = atomsInLevel.map(a => getSymbolAtomName(a, parents[parentLevel]))

		const integralIndexArray: number[] = []
		const differentialArray: { index: number; symbolName: string }[] = []

		// find current level’s `\int`s and `\differentD`s that target `atom`’s symbol name
		symbolNamesInLevel.forEach((symbolName, i) => {
			if (symbolName === '\\int') {
				integralIndexArray.push(i)
			} else if (symbolName === '\\differentialD') {
				// track the `\differentialD` if it is not at the end and is followed by `atom`’s symbol name
				if (i !== symbolNamesInLevel.length - 1 && symbolNamesInLevel[i + 1] === atomSymbolName) {
					differentialArray.push({ index: i, symbolName: symbolNamesInLevel[i + 1] })
				}
			}
		})

		// finish checking level if it does not contain any `\int`s or `\differentialD`s that target `atom`’s symbol name
		if (integralIndexArray.length === 0 || differentialArray.length === 0) continue

		// loop through the `\differentD`s with symbols in the current level to find their paired `\int`
		for (let index = 0; index < differentialArray.length; index++) {
			const currentDifferential = differentialArray[index]
			// find the matching `\int` and remove it from `integralIndexArray` so it can only match once
			const matchingIntegralArrayIndex = findLastIndex(integralIndexArray, i => i < currentDifferential.index)
			const matchingIntegralIndex = integralIndexArray.splice(matchingIntegralArrayIndex, 1)[0]

			// return true if `atom` is "inside" the given `\int` and also the target of the `\differentialD`
			if (
				// and the `\int` comes before `atom`
				matchingIntegralIndex < currentLevelAtomIndex &&
				// and the `\differentialD` is either somewhere after `atom`
				// or immediately before `atom`
				(currentDifferential.index > currentLevelAtomIndex ||
					currentDifferential.index + 1 === currentLevelAtomIndex)
			) {
				return true
			}
		}
	}

	return false
}

export const isFormulaValid = (
	latex: string,
	allVariables: VariableState[],
	supportedCommands?: string[],
	unsupportedCommands?: string[],
	supportsNonVariableSymbols?: boolean
): VALIDITY_LEVEL => {
	const mathlistArray = MathLive.latexToMathlist(latex)
	const mathlist = MathLive.MathAtom.makeRoot('math', mathlistArray)
	if (
		getUndefinedVariableNames(mathlist, allVariables).length > 0 ||
		getRichTextVariableNames(mathlist, allVariables).length > 0
	) {
		return VALIDITY_LEVEL.INVALID_FOR_USE_ERROR
	}

	let invalidCommands: string[] = []
	let invalidSymbols: string[] = []
	const setInvalidCommands = (value: string[]) => {
		invalidCommands = value
	}
	const setInvalidSymbols = (value: string[]) => {
		invalidSymbols = value
	}

	updateAtoms(
		mathlist,
		false,
		false, // does not support styles
		allVariables,
		undefined,
		supportedCommands,
		unsupportedCommands,
		setInvalidCommands,
		supportsNonVariableSymbols,
		setInvalidSymbols
	)

	if (invalidCommands.length > 0 || (!supportsNonVariableSymbols && invalidSymbols.length > 0)) {
		return VALIDITY_LEVEL.INVALID_FOR_USE_ERROR
	}

	return VALIDITY_LEVEL.VALID
}

/** Iterates all atoms in the mathlist and updates css classes for any variables and replaces its content if renamed. */
export const updateAtoms = (
	mathlist: any,
	supportsRichText: boolean,
	supportsStyles: boolean,
	variables?: VariableState[],
	renamedVariables?: Array<{ from: VariableState; to: VariableState }>,
	supportedCommands?: string[],
	unsupportedCommands?: string[],
	setInvalidCommands?: (value: string[]) => void,
	supportsNonVariableSymbols?: boolean,
	setInvalidSymbols?: (value: string[]) => void
) => {
	/* istanbul ignore next */
	if (!mathlist) {
		return
	}
	const solutionSymbolResult = getSymbols(mathlist)
	const invalidSymbols: string[] = []
	const invalidCommands: string[] = []
	mathlist.forEach((atom: any, parents: any[]) => {
		const {
			isNonVariableSymbol,
			isInvalidCommand,
			isInvalidFracDenominator,
			isInvalidGrouping,
			isUnmatchedDelimiter
		} = getAtomErrors(atom, parents, solutionSymbolResult, supportedCommands, unsupportedCommands)
		updateAtomForError(
			atom,
			supportsStyles,
			isNonVariableSymbol,
			isInvalidCommand,
			isInvalidFracDenominator,
			isInvalidGrouping,
			isUnmatchedDelimiter,
			supportsNonVariableSymbols
		)
		if (isNonVariableSymbol && !supportsNonVariableSymbols) {
			invalidSymbols.push(atom.latex)
		} else if (isInvalidCommand || isInvalidFracDenominator || isInvalidGrouping || isUnmatchedDelimiter) {
			invalidCommands.push(isInvalidGrouping ? `${atom.leftDelim} ${atom.rightDelim}` : atom.latex)
		} else {
			updateAtomForVariables(atom, supportsRichText, supportsStyles, variables, renamedVariables)
		}
	})
	if (setInvalidSymbols) {
		setInvalidSymbols(invalidSymbols)
	}
	if (setInvalidCommands) {
		setInvalidCommands(invalidCommands)
	}
}

const updateAtomForError = (
	atom: any,
	supportsStyles: boolean,
	isNonVariableSymbol: boolean,
	isInvalidCommand: boolean,
	isInvalidFracDenominator: boolean,
	isInvalidGrouping: boolean,
	isUnmatchedDelimiter: boolean,
	supportsNonVariableSymbols?: boolean
) => {
	if ((!supportsNonVariableSymbols && isNonVariableSymbol) || isInvalidCommand || isUnmatchedDelimiter) {
		atom.applyStyle({
			originalType: atom.originalType || atom.type, // store original type, for removing error state
			type: 'error',
			cssClass: '',
			originalStyle: {
				cssClass: atom.cssClass
			}
		})
	} else if (isInvalidFracDenominator || isInvalidGrouping) {
		// changing the type of 'genfrac' or 'leftright' to 'error' messes up the output display, use a style instead
		atom.applyStyle({
			cssClass: 'atom-error',
			originalStyle: {
				cssClass: atom.cssClass
			}
		})
	} else {
		resetAtom(atom, supportsStyles)
	}
}

export const getIsUnmatchedDelimiter = (
	atom: any,
	parent: any
): { isUnmatched: boolean; isLeft: boolean; matchingDelimiter?: string } => {
	// do not process if atom is type "leftright", or atom does not contain a delimiter symbol
	if (atom.type === 'leftright' || !DELIMITER_SYMBOLS.includes(atom.latex)) {
		return { isUnmatched: false, isLeft: false, matchingDelimiter: undefined }
	}
	// get the parent collection containing the atom
	const atomAndSiblings = getParentPropertyContainingAtom(atom, parent)
	const index = atomAndSiblings.indexOf(atom)
	// determine if the delimiter is a left or right, and what its match is
	let isLeft = Object.keys(DELIMITER_PAIRS).includes(atom.latex)
	const siblingsBefore = atomAndSiblings.slice(0, index)
	const siblingsAfter = atomAndSiblings.slice(index + 1)
	// find the subset of siblings to search
	let siblingsToCheck: any[]
	let matchingDelimiter: string
	if (isLeft) {
		siblingsToCheck = siblingsAfter
		matchingDelimiter = DELIMITER_PAIRS[atom.latex]
	} else {
		siblingsToCheck = siblingsBefore
		matchingDelimiter = DELIMITER_PAIRS_INVERTED[atom.latex]
	}
	let hasMatchingSibling = false
	// if the left and right delimiters are the same, we don't yet know if the atom is left or right
	const isMatchingDelimiterTheSame = atom.latex === matchingDelimiter
	if (isMatchingDelimiterTheSame) {
		const matchCountBefore = siblingsBefore.filter(sibling => sibling.latex === atom.latex).length
		const matchCountAfter = siblingsAfter.filter(sibling => sibling.latex === atom.latex).length
		// the delimiter is left if it has no matches or an even number of matches before
		isLeft = matchCountBefore === 0 || matchCountBefore % 2 === 0
		// a left delimiter just needs any match after, to have a match
		// a right delimiter needs an odd number of matches before, to have a match
		hasMatchingSibling = isLeft ? matchCountAfter > 0 : matchCountBefore % 2 !== 0
	} else {
		const duplicateCount = siblingsToCheck.filter(sibling => sibling.latex === atom.latex).length
		const matchCount = siblingsToCheck.filter(sibling => sibling.latex === matchingDelimiter).length
		hasMatchingSibling = matchCount - duplicateCount > 0
	}
	return { isUnmatched: !hasMatchingSibling, isLeft, matchingDelimiter }
}

export const getAtomErrors = (
	atom: any,
	parents: any[],
	symbolResult: SymbolResult,
	supportedCommands?: string[],
	unsupportedCommands?: string[]
) => {
	const parent = parents && parents[0]
	const isInsideVariable = !!parent && parent.type === 'variable'
	const isFraction = atom.type === 'genfrac'
	const isLeftRight = atom.type === 'leftright'

	const isNonVariableSymbol =
		!isInsideVariable &&
		typeof atom.latex === 'string' &&
		symbolResult.symbols.includes(getSymbolAtomName(atom, parent)) &&
		!isValidIntegralSymbol(atom, parents, symbolResult)

	const isInvalidCommand =
		!isInsideVariable &&
		!isFraction &&
		!isLeftRight &&
		typeof atom.latex === 'string' &&
		!isCommandSupported(atom.latex, supportedCommands, unsupportedCommands)

	// Check for fraction error separately since changing the type messes up the display output.
	// Match on the denominator to put the error line on the bottom.
	const isInvalidFracDenominator =
		!!parent &&
		parent.type === 'genfrac' &&
		typeof parent.latex === 'string' &&
		!isCommandSupported(parent.latex, supportedCommands, unsupportedCommands) &&
		(parent.denom.some((d: any) => d.id !== undefined)
			? parent.denom.some((d: any) => d.id === atom.id)
			: parent.denom.some((d: any) => d.body === atom.body))

	// Check for invalid 'leftright' grouping atoms, which will need special error styling, similar to fractions.
	const isInvalidGrouping =
		!isInsideVariable &&
		isLeftRight &&
		(!isCommandSupported(atom.leftDelim, supportedCommands, unsupportedCommands) ||
			(atom.rightDelim !== '?' && !isCommandSupported(atom.rightDelim, supportedCommands, unsupportedCommands)))

	// e.g. "\lfloor" without a corresponding "\rfloor" after it, when not using a "leftright" atom
	const isUnmatchedDelimiter =
		!isInsideVariable &&
		!isFraction &&
		!isLeftRight &&
		typeof atom.latex === 'string' &&
		!!parent &&
		getIsUnmatchedDelimiter(atom, parent).isUnmatched

	return {
		isNonVariableSymbol,
		isInvalidCommand,
		isInvalidFracDenominator,
		isInvalidGrouping,
		isUnmatchedDelimiter
	}
}

/**
 * Check whether the given string has the following formatting errors that can be automatically fixed.
 * * Unclosed delimiters such as parentheses
 * * Empty superscript/subscript which would be invisible to the user
 *
 * @param mathString The mathlive latex string to check
 * @param mathlistOrAtom (Optional) The mathlist/atom that the latex string was generated from.
 * If not provided, a mathlist will be generated from the mathString.
 * Pass this in when you already have both string and mathlist/atom.
 */
export const hasFixableMathFormatErrors = (mathString: string, mathlistOrAtom?: any) => {
	// If there is an "unfinished" mathlive atom, do not try to fix the latex since that unfinished command
	// does not show up in the latex and we end up recursing infinitely trying to fix it.
	let hasCommandInProgress = false
	mathlistOrAtom?.forEach((atom: any) => {
		if (
			// _{}, ^{}
			(atom.type === 'msubsup' && (atom.superscript?.[1]?.body === '\\' || atom.subscript?.[1]?.body === '\\')) ||
			// \sqrt[{}]{}
			(atom.type === 'surd' && atom.body?.[1]?.body === '\\') ||
			// \vec{}, \bar{}, etc.
			(atom.type === 'accent' && atom.body?.[1]?.body === '\\') ||
			// \frac{}{}
			(atom.type === 'genfrac' && (atom.numer?.[1]?.body === '\\' || atom.denom?.[1]?.body === '\\'))
		)
			hasCommandInProgress = true
	})
	if (hasCommandInProgress) return false

	const fixableMathFormatErrors: Array<string | RegExp> = [
		// unclosed "leftright" delimiters
		/\\m?(left|right)[.?]/,
		// empty superscript/subscript
		'^{}',
		'_{}',
		/\\sqrt(\[.*\])?{}/g, // empty sqrt (possibly full index)
		'\\sqrt[]', // empty sqrt index
		// genfrac
		'\\frac{}', // empty numerator
		/\\frac{.*}{}/g, // empty denom (possibly full numerator)
		// accent
		'\\bar{}',
		'\\vec{}',
		'\\hat{}',
		'\\dot{}',
		'\\ddot{}',
		'\\mathring{}',
		'\\breve{}',
		'\\acute{}',
		'\\tilde{}',
		'\\grave{}',
		// starts with superscript or subscript
		/^\^{?/,
		/^_{?/,
		// ends with unclosed brace
		/{$/
	]

	const localMathlist = mathlistOrAtom ?? MathLive.MathAtom.makeRoot('math', MathLive.latexToMathlist(mathString))
	let hasHiddenGroups = false
	localMathlist.forEach((atom: any) => {
		if (isHiddenGroup(atom)) {
			hasHiddenGroups = true
		}
	})

	let hasUnmatchedDelimiters = false
	localMathlist.forEach((atom: any, parents: any[]) => {
		if (getIsUnmatchedDelimiter(atom, parents[0]).isUnmatched) {
			hasUnmatchedDelimiters = true
		}
	})

	return (
		fixableMathFormatErrors.some(e => (typeof e === 'object' ? e.test(mathString) : mathString.includes(e))) ||
		hasHiddenGroups ||
		hasUnmatchedDelimiters
	)
}

/** local cache of inputs and outputs from `fixMathFormatErrors` */
const knownFixMathFormatErrorsResults: Record<string, string> = {}
/** local cache of latex that `fixMathFormatErrors` failed to fix after all iterations */
const unFixableLatex: string[] = []

export const fixMathFormatErrors = (mathlistOrAtom: any, outputStyles = false): any => {
	// if mathlistOrAtom is a an EditableMathList, we need to target "root"
	const originalLatex = (mathlistOrAtom.root ?? mathlistOrAtom).toLatex({ outputStyles })
	let updatedLatex = originalLatex

	// short-circuit for values already passed to `fixMathFormatErrors` in the past
	if (Object.keys(knownFixMathFormatErrorsResults).includes(originalLatex)) {
		return knownFixMathFormatErrorsResults[originalLatex]
	}
	// short-circuit for latex that `fixMathFormatErrors` failed to fix after all iterations (a known output in knownLatexFixes)
	if (unFixableLatex.includes(originalLatex)) {
		return originalLatex
	}
	// short-circuit if there are actually no errors to fix in the latex generated from `mathlistOrAtom`
	const hasErrors = hasFixableMathFormatErrors(originalLatex, mathlistOrAtom)
	if (!hasErrors) {
		knownFixMathFormatErrorsResults[originalLatex] = updatedLatex
		return updatedLatex
	}

	const maxIterations = 50
	try {
		let previousUpdatedLatex = originalLatex
		for (let i = 0; i < maxIterations; i++) {
			// attempt to fix errors
			closeDelimiters(mathlistOrAtom)
			addPlaceholders(mathlistOrAtom)
			expandHiddenGroups(mathlistOrAtom)
			expandStartingSuperscriptsAndSubscripts(mathlistOrAtom)

			// check if the latex still contains errors
			updatedLatex = (mathlistOrAtom.root ?? mathlistOrAtom).toLatex({ outputStyles })
			const stillHasErrors = hasFixableMathFormatErrors(updatedLatex, mathlistOrAtom)

			// break and return `updatedLatex` if errors are fixed
			if (!stillHasErrors) {
				break
			}

			// if the latex was not fixed and not changed from the previous iteration, do not keep trying to fix,
			// or if the latex still has errors after the last iteration,
			// or the latex is increasing in length too much,
			// then throw an exception and return the most recent `updatedLatex`.
			// this usually means `hasFixableMathFormatErrors` is finding errors this method could not fix.
			const didNotChange = updatedLatex === previousUpdatedLatex
			const isLastIteration = i === maxIterations - 1
			const isTooLong = updatedLatex.length >= originalLatex.length * 100
			if (didNotChange || isLastIteration || isTooLong) {
				// if the latex still has errors after the last iteration, or the length became too long
				if (isLastIteration || isTooLong) {
					// cache the result, so it is not retried as an input to this method
					unFixableLatex.push(updatedLatex)
				}
				throw new Error(
					`fixMathFormatErrors did not fix latex '${updatedLatex}', originalLatex: ${originalLatex}`
				)
			}

			// store `updatedLatex` for the next iteration to compare
			previousUpdatedLatex = updatedLatex
		}
	} catch (error) {
		// suppress and log any exceptions
		Sentry.captureException(error)
	}

	// cache the result
	knownFixMathFormatErrorsResults[originalLatex] = updatedLatex

	return updatedLatex
}

export const getFixedLatexFromMathlist = (mathlist: any, outputStyles = false) => {
	// make a copy of the mathlist since fixMathFormatErrors will edit it in-place
	const mathlistCopy = cloneDeep(mathlist)
	const fixedLatex = fixMathFormatErrors(mathlistCopy, outputStyles)
	return fixedLatex
}

export const getFixedLatex = (latex: string, outputStyles = false) => {
	const mathlistArray = MathLive.latexToMathlist(latex)
	const rootAtom = MathLive.MathAtom.makeRoot('math', mathlistArray)
	const fixedLatex = fixMathFormatErrors(rootAtom, outputStyles)
	return fixedLatex
}

export const tryToFixLatex = (latex: string) => {
	if (latex && hasFixableMathFormatErrors(latex)) {
		return getFixedLatex(latex)
	}
	return latex
}

/**
 * Given `mathlistOrAtom`, find any atoms with an unclosed right delimiter, e.g. "?", and close it if possible.
 *
 * `mathlistOrAtom` is edited in-place.
 * @param mathlistOrAtom A mathlist or atom
 */
export const closeDelimiters = (mathlistOrAtom: any) => {
	const atomsAndParents = getNestedAtomsAndParents(mathlistOrAtom)

	// loop to make group changes from the bottom up
	// this is necessary since we are modifying `mathlistOrAtom` in place (update children before parent)
	atomsAndParents.forEach(atomAndParents => {
		const { atom, parents } = atomAndParents
		// unclosed left delimiter
		if (atom.type === 'leftright') {
			if (
				(atom.leftDelim === '?' || atom.leftDelim === '.' || !atom.leftDelim) &&
				Object.prototype.hasOwnProperty.call(DELIMITER_PAIRS_INVERTED, atom.rightDelim)
			) {
				atom.leftDelim = (DELIMITER_PAIRS_INVERTED as any)[atom.rightDelim]
			}
			// unclosed right delimiter
			if (
				(atom.rightDelim === '?' || atom.rightDelim === '.' || !atom.rightDelim) &&
				Object.prototype.hasOwnProperty.call(DELIMITER_PAIRS, atom.leftDelim)
			) {
				atom.rightDelim = (DELIMITER_PAIRS as any)[atom.leftDelim]
			}
		} else {
			const { isUnmatched, isLeft, matchingDelimiter } = getIsUnmatchedDelimiter(atom, parents[0])
			if (isUnmatched) {
				const parent = parents[0]
				const containingProperty = getParentPropertyContainingAtom(atom, parent)
				if (isLeft) {
					const matchingAtom = new MathLive.MathAtom.MathAtom('math', 'mclose')
					matchingAtom.latex = matchingDelimiter
					containingProperty.push(matchingAtom)
				} else {
					const matchingAtom = new MathLive.MathAtom.MathAtom('math', 'mopen')
					matchingAtom.latex = matchingDelimiter
					containingProperty.splice(0, 0, matchingAtom)
				}
			}
		}
	})
}

const createFirstAtom = () => new MathLive.MathAtom.MathAtom('', 'first')

const createPlaceholderAtom = () => new MathLive.MathAtom.MathAtom('math', 'placeholder', '⬚')

const isAtomArrayEmpty = (atomArray: any) =>
	Array.isArray(atomArray) && (atomArray.length === 0 || (atomArray.length === 1 && atomArray[0].type === 'first'))

const isHiddenGroup = (atom: any) =>
	(atom.type === 'group' && atom.latexOpen === '{') || (atom.type === 'leftright' && atom.leftDelim === '.')

const getParentPropertyContainingAtom = (atom: any, parent: any): any[] => {
	if (parent.body && Array.isArray(parent.body) && parent.body.includes(atom)) {
		return parent.body
	}
	if (parent.superscript && parent.superscript.includes(atom)) {
		return parent.superscript
	}
	if (parent.subscript && parent.subscript.includes(atom)) {
		return parent.subscript
	}
	if (parent.underscript && parent.underscript.includes(atom)) {
		return parent.underscript
	}
	if (parent.numer && parent.numer.includes(atom)) {
		return parent.numer
	}
	if (parent.denom && parent.denom.includes(atom)) {
		return parent.denom
	}
	if (parent.index && parent.index.includes(atom)) {
		return parent.index
	}
	if (parent.array) {
		for (const row of parent.array) {
			for (const cell of row) {
				for (const atom of cell) {
					if (cell.includes(atom)) {
						return cell
					}
				}
			}
		}
	}
	return []
}

/**
 * Construct an array of atoms and parents, ordered with MOST nested atoms first, by using parents length
 */
const getNestedAtomsAndParents = (mathlistOrAtom: any) => {
	let atomsAndParents: any[] = []
	mathlistOrAtom.forEach((atom: any, parents: any[]) => {
		atomsAndParents.push({ atom, parents })
	})
	atomsAndParents = atomsAndParents.sort((o1, o2) => o2.parents.length - o1.parents.length)
	return atomsAndParents
}

/**
 * Given `mathlistOrAtom`, find any commands that should contain placeholders and insert them where needed.
 *
 * `mathlistOrAtom` is edited in-place.
 * @param mathlistOrAtom A mathlist or atom
 */
export const addPlaceholders = (mathlistOrAtom: any) => {
	mathlistOrAtom.forEach((atom: any) => {
		// _{}, ^{}
		if (atom.type === 'msubsup' || atom.superscript || atom.subscript) {
			if (atom.type === 'msubsup' && atom.superscript === null) {
				atom.superscript = [createFirstAtom(), createPlaceholderAtom()]
			} else if (isAtomArrayEmpty(atom.superscript)) {
				atom.superscript.splice(1, 0, createPlaceholderAtom())
			}
			if (atom.type === 'msubsup' && atom.subscript === null) {
				atom.subscript = [createFirstAtom(), createPlaceholderAtom()]
			} else if (isAtomArrayEmpty(atom.subscript)) {
				atom.subscript.splice(1, 0, createPlaceholderAtom())
			}
		}
		// \sqrt[]{}
		else if (atom.type === 'surd') {
			if (isAtomArrayEmpty(atom.index)) {
				atom.index.push(createPlaceholderAtom())
			}
			if (isAtomArrayEmpty(atom.body)) {
				atom.body.push(createPlaceholderAtom())
			}
		}
		// \vec{}, \bar{}, etc.
		else if (atom.type === 'accent') {
			if (isAtomArrayEmpty(atom.body)) {
				atom.body.push(createPlaceholderAtom())
			}
		}
		// \frac{}{}
		else if (atom.type === 'genfrac') {
			if (isAtomArrayEmpty(atom.numer)) {
				atom.numer.push(createPlaceholderAtom())
			}
			if (isAtomArrayEmpty(atom.denom)) {
				atom.denom.push(createPlaceholderAtom())
			}
		}
	})
}

/**
 * Given `mathlistOrAtom`, find any non-visible group atoms and expand their body contents onto the group's parent atom.
 *
 * `mathlistOrAtom` is edited in-place.
 * @param mathlistOrAtom A mathlist or an atom
 */
export const expandHiddenGroups = (mathlistOrAtom: any) => {
	// construct an array of atoms and parents, ordered with MOST nested atoms first, by using parents length
	const atomsAndParents = getNestedAtomsAndParents(mathlistOrAtom)

	// loop to make group changes from the bottom up
	// this is necessary since we are modifying `mathlistOrAtom` in place (update children before parent)
	atomsAndParents.forEach(atomAndParents => {
		const { atom, parents } = atomAndParents
		if (isHiddenGroup(atom)) {
			const parent = parents[0]
			const containingProperty = getParentPropertyContainingAtom(atom, parent)
			const index = containingProperty.indexOf(atom)
			containingProperty.splice(index, 1, ...atom.body)
		}
	})
}

/**
 * Given `mathlistOrAtom`, if it starts with a superscript or subscript atom, remove it and expand its contents to the parent root atom.
 *
 * `mathlistOrAtom` is edited in-place.
 * @param mathlistOrAtom A mathlist or atom
 */
export const expandStartingSuperscriptsAndSubscripts = (mathlistOrAtom: any) => {
	mathlistOrAtom.forEach((atom: any, parents: any[]) => {
		// only applies when atom is the second child of the rootAtom
		// the first child is always of type "first"
		// `parents` is an array of ancestors, so having 1 parent means it is a child of the root
		if (atom.type === 'msubsup' && parents.length === 1) {
			const parent = parents[0]
			const containingProperty = getParentPropertyContainingAtom(atom, parent)
			const index = containingProperty.indexOf(atom)
			if (index === 1) {
				// 'msubsup' can have BOTH superscript and subscript, so expand both
				const children: any[] = []
				if (atom.superscript) {
					children.push(...atom.superscript)
				}
				if (atom.subscript) {
					children.push(...atom.subscript)
				}
				containingProperty.splice(index, 1, ...children)
			}
		}
	})
}

export const isCommandSupported = (latex: string, supportedCommands?: string[], unsupportedCommands?: string[]) => {
	const expr = /^[a-zA-Z0-9,.\t\r\n ]+$/
	const isSupported =
		!isNil(latex) &&
		// allows empty latex
		(latex.length === 0 ||
			// allows certain non-commands (text, numbers, whitespace)
			!!expr.exec(latex) ||
			// allows supported and excludes unsupported commands, if defined
			((!supportedCommands || supportedCommands.includes(latex)) &&
				(!unsupportedCommands || !unsupportedCommands.includes(latex))))
	return isSupported
}

/** If atom is a variable, updates its css classes and replaces its content if renamed. */
const updateAtomForVariables = (
	atom: any,
	supportsRichText: boolean,
	supportsStyles: boolean,
	variables: VariableState[] | undefined,
	renamedVariables?: Array<{ from: VariableState; to: VariableState }>
) => {
	if (atom.type !== 'variable' || !atom.body || !atom.body[0]) {
		resetAtom(atom, supportsStyles)
		return
	}
	let name = getVariableAtomName(atom)
	const renamedVariable = renamedVariables ? renamedVariables.filter(v => v.from.name === name)[0] : undefined
	if (renamedVariable) {
		name = renamedVariable.to.name
		atom.body = MathLive.latexToMathlist(name)
	}
	// account for "legacy" variable names
	// e.g. when the variable may be saved with an unwrapped single char subscript
	// mathlive automatically wraps all subscripts, so also comparing to an unwrapped version
	// allows these to not be seen as an error
	const names = [name]
	if (variableNameWrappedSingleSubRegEx.test(name)) {
		names.push(unwrapWrappedSingleSubscript(name))
	}
	const pillClass = getPillClass(names, supportsRichText, variables)
	atom.applyStyle({
		cssClass: `pill ${pillClass}`,
		type: atom.originalType || atom.type,
		originalStyle: {
			cssClass: atom.cssClass
		}
	})
}

export const getPillClass = (names: string[], supportsRichText: boolean, variables: VariableState[] | undefined) => {
	const existingVariable = variables?.find(variable => names.includes(variable.name))
	const isRichText = variables?.find(
		variable => names.includes(variable.name) && variable.variableType === VARIABLE_TYPE.RICH_TEXT
	)
	const hasParameters = variables?.find(variable => names.includes(variable.name) && !!variable.parameters)
	return !existingVariable || (!supportsRichText && isRichText)
		? 'red-pill-outline'
		: !hasParameters && !isRichText
		? 'green-pill-outline'
		: 'green-pill'
}

const resetAtom = (atom: any, supportsStyles: boolean) => {
	const currentStyle = {
		...atom.getStyle(),
		// `getStyle()` flattens the fontFamily props, but we need to keep them separated
		baseFontFamily: atom.baseFontFamily,
		fontFamily: atom.fontFamily,
		autoFontFamily: atom.autoFontFamily
	}
	const originalStyle = atom.originalStyle
	const diff: any = {
		type: atom.originalType || atom.type,
		...(originalStyle ? originalStyle : currentStyle)
	}
	// if not supported, clear styles
	// do not clear font family if the atom's latex is a known command
	// NOTE: `EXPRESSION_COMMANDS` covers all possible known commands used in any formula that doesn't support styles
	if (!supportsStyles) {
		diff.color = ''
		diff.backgroundColor = ''
		diff.fontSize = ''
		if (!EXPRESSION_COMMANDS.includes(atom.latex)) {
			diff.fontFamily = ''
			diff.baseFontFamily = ''
		}
		diff.fontSeries = ''
		diff.fontShape = ''
	}
	//getLogger().debug('resetAtom', atom.latex, atom.type, atom.body, { currentStyle, originalStyle, newStyle: diff })
	atom.applyStyle(diff)
}

export const getSupportedInlineShortcuts = (supportedCommands?: string[], unsupportedCommands?: string[]) => {
	const supportedInlineShortcuts = Object.entries(
		INLINE_SHORTCUTS as Dictionary<string | { mode?: string; value: string; after?: string }>
	).reduce((returnObject, entry) => {
		const [key, inlineValue] = entry
		const value = typeof inlineValue === 'string' ? inlineValue : inlineValue.value
		// find the latex command that starts the shortcut value, to account for when an example template is being used
		// e.g. given '\\sum_{#?}^{#?}' this will match just the command '\\sum'
		const commandMatch = /^(\\[a-zA-Z{}]+)(?:[ _]|$)/.exec(value)
		const command = commandMatch?.[1] ?? value
		// exclude the shortcut using `supportedCommands` and/or `unsupportedCommands`
		if (
			(!!supportedCommands && !supportedCommands.includes(command)) ||
			(!!unsupportedCommands && unsupportedCommands.includes(command))
		) {
			return returnObject
		}
		returnObject[key] = inlineValue
		return returnObject
	}, {} as Dictionary<string | { mode?: string; value: string; after?: string }>)
	return supportedInlineShortcuts
}

/**
 * Generates a scale factor for font size using the nested hierarchy of fractions, superscripts, and subscripts.
 * Minimum scale factor is `1`.
 * @param mathlist A mathlist from mathlive
 */
export const getMathlistFontScaleFactor = (mathlist: any) => {
	let scaleFactor = 1
	mathlist.forEach((atom: any, parents: any) => {
		// find the number of fractions, superscripts or subscripts in this atom's hierarchy
		const nestedCount = parents.filter((a: any) => a.type === 'genfrac' || a.type === 'msubsup').length
		// MathLive only scales down fonts two times
		// * once for the 2nd level of nesting
		// * once for all levels 3+
		// use either 1, 2, or 3, based on the level of nesting
		const atomScaleFactor = Math.min(3, Math.max(1, nestedCount))
		scaleFactor = Math.max(scaleFactor, atomScaleFactor)
	})
	return scaleFactor
}

/** Check if a latex string is equivalent to infinity. */
export const isInf = (value: string) => value.replace('\\%', '').replace('\\$', '').replace('-', '') === '\\infty'
